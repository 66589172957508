import anime from "../node_modules/animejs/lib/anime.es.js";

window.addEventListener("load", () => {
   heroImg.classList.remove("animate");
});

const allLinks = document.querySelectorAll("a:link");

const navBtn = document.querySelector(".btn-mobile-nav");

const headerEl = document.querySelector(".header");
navBtn.addEventListener("click", function () {
   headerEl.classList.toggle("nav-open");
});

allLinks.forEach(function (link) {
   link.addEventListener("click", function (e) {
      const href = link.getAttribute("href");

      //Scroll back to top
      if (href === "#") window.scrollTo({ top: 0, behavior: "smooth" });

      // Scroll to Links
      if (href !== "#" && href.startsWith("#")) {
         const sectionEl = document.querySelector(href);
         sectionEl.scrollIntoView({ behavior: "smooth" });
      }

      // Close mobile navigation
      if (link.classList.contains("nav-item")) {
         headerEl.classList.toggle("nav-open");
      }
   });
});

const allSections = document.querySelectorAll(".section");

const revealSection = function (entries, observer) {
   const [entry] = entries;
   if (!entry.isIntersecting) return;
   entry.target.classList.remove("section--hidden");
   observer.unobserve(entry.target);
};

const sectionObserver = new IntersectionObserver(revealSection);

allSections.forEach(function (section) {
   sectionObserver.observe(section);
   // section.classList.add("section--hidden");
});

const animateBar1 = document.querySelectorAll(".bar-1");
const animateBar2 = document.querySelectorAll(".bar-2");
const animateBar3 = document.querySelectorAll(".bar-3");

const playAnimation = function (entries, observer) {
   const [entry] = entries;
   if (!entry.isIntersecting) {
      animateBar1.forEach((el) => {
         root.style.setProperty("--anim1-value", 0);
         el.classList.remove("animate-bar1");
      });
      animateBar2.forEach((el) => {
         root.style.setProperty("--anim2-value", 0);
         el.classList.remove("animate-bar2");
      });
      animateBar3.forEach((el) => {
         root.style.setProperty("--anim3-value", 0);
         el.classList.remove("animate-bar3");
      });
   }
   if (entry.isIntersecting) {
      animateBar1.forEach((el) => {
         root.style.setProperty("--anim1-value", bar1);
         el.classList.add("animate-bar1");
      });

      animateBar2.forEach((el) => {
         root.style.setProperty("--anim2-value", bar2);
         el.classList.add("animate-bar2");
      });
      animateBar3.forEach((el) => {
         root.style.setProperty("--anim3-value", bar3);
         el.classList.add("animate-bar3");
      });
   }
};

const graphSectionObserver = new IntersectionObserver(playAnimation, {
   root: null,
   threshold: 0.7,
});

let root = document.documentElement;
const bar1 = document.querySelector(".bar-num-1").textContent;
const bar2 = document.querySelector(".bar-num-2").textContent;
const bar3 = document.querySelector(".bar-num-3").textContent;

const graphContainer = document.querySelector(".container");
graphSectionObserver.observe(graphContainer);

const heroSection = document.querySelector(".hero-section");
const nav = document.querySelector(".nav");

const navHeight = nav.getBoundingClientRect().height;

const stickyNav = function (entries) {
   const [entry] = entries;

   if (!entry.isIntersecting) {
      heroSection.style.paddingBottom = "6.4rem";
      headerEl.classList.add("sticky");
   } else {
      heroSection.style.paddingBottom = "0";
      headerEl.classList.remove("sticky");
   }
};

const headerObserver = new IntersectionObserver(stickyNav, {
   root: null,
   threshold: 0,
   rootMargin: `-${navHeight}px`,
});

headerObserver.observe(heroSection);

const f1Text = document.querySelector(".f1").innerHTML.slice(0, 2);
const f2Text = document.querySelector(".f2").innerHTML.slice(0, 2);
const f3Text = document.querySelector(".f3").innerHTML.slice(0, 2);
const f4Text = document.querySelector(".f4").innerHTML.slice(0, 2);

const f1Duration = f1Text * 0.03 * 1000;
const f2Duration = f2Text * 0.03 * 1000;
const f3Duration = f3Text * 0.03 * 1000;
const f4Duration = f4Text * 0.03 * 1000;

const animateNumbers = function (entries, observer) {
   const [entry] = entries;
   if (entry.isIntersecting) {
      anime({
         targets: ".f1",
         innerHTML: [0, 80],
         round: 1,
         easing: "linear",
         duration: f1Duration,
      });
      anime({
         targets: ".f2",
         innerHTML: [0, 68],
         round: 1,
         easing: "linear",
         duration: f2Duration,
      });
      anime({
         targets: ".f3",
         innerHTML: [0, 77],
         round: 1,
         easing: "linear",
         duration: f3Duration,
      });
      anime({
         targets: ".f4",
         innerHTML: [0, 73],
         round: 1,
         easing: "linear",
         duration: f4Duration,
      });
   }
};

const factNumbersObserver = new IntersectionObserver(animateNumbers, {
   root: null,
   threshold: 0.7,
});
const factContainer = document.querySelector(".facts-container");
factNumbersObserver.observe(factContainer);

document.querySelector(".year").textContent = new Date().getFullYear();

// //
// // Form Submission Using JS to get Modal Window
// contactForm.addEventListener("submit", (e) => {
//   e.preventDefault();

//   let data = new FormData(e.target);
//   // Use Fetch api from POST contact.php
//   return false;
// });
